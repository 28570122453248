import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'
import TrainingPage from '../ui/pages/TrainingPage'

const Training = props => (
  <>
    <HelmetComponent
      title={metaTags.trainingTitle}
      description={metaTags.training}
      page="de/training"
    />
    <AppWrapper {...props} lang="de">
      <TrainingPage />
    </AppWrapper>
  </>
)

export default Training
